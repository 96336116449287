import { Component } from "react";
import { Icons } from "../../utils/react-svg";
import { Font, Color, rem, responsive, Opacity } from "../../utils/style";
import styled from "styled-components";
import sanitizeHtml from "isomorphic-html-sanitize";
import { parseMarkdown } from "../../utils/markdown";

const BarrierBusterWrapper = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0;
  list-style: none;

  ${responsive.sm`
    justify-content: space-between;
  `};
`;

const BarrierBusterItem = styled.li`
  flex: 0 0 calc(50% - (14px / 2));
  text-align: center;
  margin-top: 40px;
  margin-right: 14px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  ${responsive.sm`
    flex: 0 0 calc(33% - (100px / 3));
    margin-right: 40px;

    &:nth-child(2n) {
      margin-right: 40px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  `};

  ${responsive.md`
    flex: 0 0 calc(33% - (132px / 3));
    margin-top: 56px;
    margin-right: 66px;

    &:nth-child(2n) {
      margin-right: 66px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  `};
`;

const Icon = styled.div`
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 auto 12px;
  position: relative;

  ${responsive.md`
    width: 35px;
    height: 35px;
    margin-bottom: 25px;
  `};

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.p`
  ${Font.circular};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  margin: 0 0 8px;
  color: ${Color.ritualBlue};

  ${responsive.md`
    font-size: ${rem(20)};
    line-height: ${rem(30)};
    margin-bottom: 16px;
  `};
`;

const Body = styled.p`
  ${Font.circular};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  margin: 0;
  color: rgba(20, 43, 111, ${Opacity.light});

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(22)};
  `};
`;

export default class BarrierBusters extends Component {
  sanitizeContent(copy) {
    const sanitizedContent = sanitizeHtml(copy, { allowedTags: [] });
    return parseMarkdown(sanitizedContent);
  }

  renderItem(item, i) {
    let cleanedIconName = item.icon.replace("icon", "").replace(/-/gi, "");
    let IconElement = Icons[cleanedIconName];

    const title = this.sanitizeContent(item.title);
    const body = this.sanitizeContent(item.body);

    return (
      <BarrierBusterItem key={"value-prop" + i}>
        <Icon>
          <IconElement />
        </Icon>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Body dangerouslySetInnerHTML={{ __html: body }} />
      </BarrierBusterItem>
    );
  }

  render() {
    let { items } = this.props;

    return (
      <BarrierBusterWrapper>
        {items.map((item, i) => {
          return this.renderItem(item, i);
        })}
      </BarrierBusterWrapper>
    );
  }
}
