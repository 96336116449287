import { graphql } from "gatsby";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PageSEO from "../components/seo/Page";
import BundleCard from "../components/shop/BundleCard";
import EditorialBlock from "../components/shop/EditorialBlock";
import ProductCard from "../components/shop/ProductCard";
import ShopGrid from "../components/shop/ShopGrid";
import ShopPage from "../components/shop/ShopPage";

import useFilters from "../hooks/shop/useFilters";
import useVariation from "../hooks/useVariation";
import intlService from "../services/intl";
import productSelectors from "../store/product/selectors";
import { matchesAll } from "../utils/filter";
import metrics from "../utils/metrics";
import { getPropertiesForProductSku } from "../utils/tracking/helpers";

const FeaturedBundlesSection = ({
  featuredBundles,
  featuredBundlesHeader,
  showFeaturedBundles,
  hideCta,
  showTextPrice,
}) => {
  if (!showFeaturedBundles) return null;

  return (
    <ShopGrid header={featuredBundlesHeader}>
      {featuredBundles?.map((bundle, idx) => (
        <BundleCard
          key={`${idx}-${bundle.title}`}
          productBundle={bundle}
          hideCta={hideCta}
          showTextPrice={showTextPrice}
        />
      ))}
    </ShopGrid>
  );
};

const ScalableShopLandingPage = (props) => {
  const showPlpCta = useVariation("show-plp-cta", false);
  const showFeaturedBundles = useVariation("show-featured-bundles", false);

  const { data } = props;
  const { contentfulShopLandingPage } = data;
  const {
    filters: supportedFilters,
    header,
    title,
    body,
    valueProps,
    slug,
    pageTitle,
    pageDescription,
    socialImage,
    enableFeaturedBundles,
    featuredBundles,
    featuredBundlesHeader,
    featuredBundlesPosition = false, // true = top, false = bottom
    editorialBlock,
    products: orderedProducts,
    hideNavigation,
    pageHeadline,
  } = contentfulShopLandingPage;

  const seo = {
    pagePath: `${slug}`,
    title: pageTitle,
    description: pageDescription,
    image: {
      url: socialImage?.file?.url,
      width: socialImage?.file?.details?.image?.width,
      height: socialImage?.file?.details?.image?.height,
    },
  };

  const stateProducts = useSelector(productSelectors.allProducts);

  const products = orderedProducts.map((product) =>
    stateProducts.find((stateProduct) => stateProduct.sku === product.sku),
  );

  const { activeFilters, filterSections, handleFilterChange } = useFilters(
    supportedFilters,
    products,
    props.location,
  );

  // Filter the current products by any active filters.
  let filteredProducts = !activeFilters
    ? products
    : products.filter((product) => matchesAll(product, activeFilters));

  let bundles =
    featuredBundles && isEmpty(activeFilters) ? featuredBundles : null;

  if (intlService.locale === "en-GB") {
    filteredProducts = filteredProducts.filter(
      (product) => product.sku !== "SLPC",
    );
  }

  useEffect(() => {
    if (!activeFilters) return;

    const filters = [];
    Object.keys(activeFilters).forEach((key) => {
      activeFilters[key].forEach((value) => {
        filters.push({
          type: key,
          value,
        });
      });
    });

    // If no filters are applied, do not report the event.
    if (!filters.length) return;

    metrics.track("Product List Filtered", {
      category: title,
      filters,
      products: filteredProducts.map((product) =>
        getPropertiesForProductSku(product.sku),
      ),
    });
  }, [JSON.stringify(filteredProducts)]);

  useEffect(() => {
    metrics.track(
      "Product List Viewed",
      {
        category: title,
        products: products.map((product) =>
          getPropertiesForProductSku(product.sku),
        ),
      },
      { addTikTokEventId: true },
    );
  }, []);

  const hasEditorialBlock = !!editorialBlock;
  const hasFeaturedBundles = !!enableFeaturedBundles;

  let gridItems = filteredProducts;

  if (hasEditorialBlock) {
    const editorialBlockPositionDesktop = editorialBlock?.positionDesktop || 2;
    const editorialBlockPositionMobile = editorialBlock?.positionMobile || 3;
    gridItems.splice(editorialBlockPositionDesktop - 1, 0, {
      editorial: true,
      desktop: true,
    });

    gridItems.splice(editorialBlockPositionMobile, 0, {
      editorial: true,
      desktop: false,
    });
  }

  const isPregnancyPage = slug === "shop/pregnancy";
  const isMultivitaminPage = slug === "shop/multivitamins";

  return (
    <>
      <PageSEO {...seo} />
      <ShopPage
        title={title}
        body={body}
        valueProps={valueProps}
        activeFilters={activeFilters}
        filterSections={filterSections}
        handleFilterChange={handleFilterChange}
        hideNavigation={hideNavigation}
        pageHeadline={pageHeadline}
        {...props}
      >
        <FeaturedBundlesSection
          featuredBundles={bundles}
          featuredBundlesHeader={featuredBundlesHeader}
          showFeaturedBundles={
            hasFeaturedBundles && featuredBundlesPosition && showFeaturedBundles
          }
        />
        <ShopGrid header={header}>
          {gridItems.map((product, i) => {
            if (product.editorial) {
              return (
                <EditorialBlock
                  {...editorialBlock}
                  desktop={product.desktop}
                  pregnancyTest={isPregnancyPage}
                  quizTest={isMultivitaminPage}
                  customHeight={title === "Pregnancy" && "480px"}
                  key={`${title}-${i}-editorioal`}
                />
              );
            }

            return (
              <ProductCard
                key={product.name}
                product={product}
                showQuickView={true}
                hideCta={!showPlpCta}
                showTextPrice={!showPlpCta}
              />
            );
          })}
          {!hasFeaturedBundles &&
            bundles?.map((bundle, idx) => (
              <BundleCard
                key={`${idx}-${bundle.title}`}
                productBundle={bundle}
                hideCta={!showPlpCta}
                showTextPrice={!showPlpCta}
              />
            ))}
        </ShopGrid>
        <FeaturedBundlesSection
          featuredBundles={bundles}
          featuredBundlesHeader={featuredBundlesHeader}
          showFeaturedBundles={
            hasFeaturedBundles &&
            !featuredBundlesPosition &&
            showFeaturedBundles
          }
          hideCta={!showPlpCta}
          showTextPrice={!showPlpCta}
        />
      </ShopPage>
    </>
  );
};

export const query = graphql`
  query ScalableShopLandingQuery($locale: String!, $slug: String!) {
    contentfulShopLandingPage(
      node_locale: { eq: $locale }
      slug: { eq: $slug }
    ) {
      slug
      hideNavigation
      title
      filters
      pageHeadline {
        childMarkdownRemark {
          html
        }
      }
      header {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      valueProps {
        name
        disclaimer
        icon {
          file {
            url
            fileName
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      editorialBlock {
        title {
          childMarkdownRemark {
            html
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        ctaText {
          ctaText
        }
        color
        showCtaArrow
        ctaUrl
        showPromoPill
        type
        positionMobile
        positionDesktop
        logo {
          description
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: NONE
            width: 300
            quality: 100
          )
        }
        mobileBackground {
          description
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 768
            quality: 90
          )
        }
        desktopBackground {
          description
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 1024
            quality: 90
          )
        }
      }
      pageTitle
      pageDescription
      socialImage {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      enableFeaturedBundles
      featuredBundlesPosition
      featuredBundlesHeader {
        childMarkdownRemark {
          html
        }
      }
      products {
        contentful_id
        sku
      }
      featuredBundles {
        id
        ... on ContentfulProductBundle {
          id
          title
          slug
          labels
          shopDescription
          flag
          shopImages {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 820
              quality: 90
            )
          }
          ritualProductOffer {
            name
            image_url
            product_offer_id
            ritualProductOfferPlans {
              quantity
              ritualInitialPlan {
                amount
              }
              ritualFuturePlan {
                amount
              }
            }
          }
          products {
            stockStatus
            sku
            name {
              name
              childMarkdownRemark {
                html
              }
            }
            shortDescription
            supplementFacts {
              id
              simpleIngredients {
                dosage
                name
                description
              }
            }
          }
        }
      }
    }
    allContentfulShopLandingPage(
      filter: { node_locale: { eq: $locale }, scalable: { eq: true } }
    ) {
      nodes {
        contentful_id
        slug
        title
      }
    }
  }
`;

export default ScalableShopLandingPage;
