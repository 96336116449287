import { useState, useEffect } from "react";

// Utils
import { getShopPath, parseShopPath } from "../../utils/shop";
import { getValuesForFilter } from "../../utils/filter";

// Services
import intl from "../../services/intl";

/**
 * Appends the specified filters as query params to the current URL.
 */
function appendParams(filters, location) {
  const path = getShopPath(location.href, filters);
  window.history.replaceState({ path }, "", path);
}

/**
 * Parses the current URL's query params for any active filters.
 */
function parseParams(search) {
  const filters = parseShopPath(search);
  // Ensures that all of the values in the paramFilters object are arrays.
  Object.entries(filters).forEach(([filter, values]) => {
    if (!Array.isArray(values)) values = [values];
    filters[filter] = values;
  });

  return filters;
}

/**
 * Removes all filters with keys or values that are not currently supported or
 * included in the set of products.
 */
function cleanFilters(filters, supportedFilters, products) {
  if (!filters) return;

  Object.entries(filters).forEach(([filter, values]) => {
    // If the current filter is not included in the set of supported filters,
    // it should be removed from the object.
    if (!supportedFilters.includes(filter)) {
      delete filters[filter];
      return;
    }

    // Filter the values array to only include values that are exist in the
    // current set of products.
    const acceptedValues = getValuesForFilter(products, filter);
    values = values.filter((value) => acceptedValues.includes(value));
    if (values.length) {
      filters[filter] = values;
    } else {
      // If there are no supported filter values, remove the filter key from
      // the object entirely.
      delete filters[filter];
    }
  });

  return filters;
}

const useFilters = (supportedFilters, products, location) => {
  function handleFilterChange(filters) {
    setActiveFilters(filters);
    appendParams(filters, location);
  }

  const [activeFilters, setActiveFilters] = useState(null);
  const [filterSections] = useState(() => {
    if (!supportedFilters) return null;

    const filterSections = [];
    supportedFilters.forEach((filter) => {
      // Filter each supported filter, return all of the possible values (e.g
      // demographics -> Men, Women, Kids & Teens) for the current set of
      // products.
      const values = getValuesForFilter(products, filter);

      // For each filter, add a new section to the filtering UI, with each of
      // the values determined above as an option.
      filterSections.push({
        header: intl.t(`tags.${filter}.title`),
        filter,
        options: values.map((value) => {
          return {
            title: intl.t(`tags.${filter}.${value}`),
            value: value,
          };
        }),
      });
    });

    return filterSections;
  });

  useEffect(() => {
    let filters = cleanFilters(
      parseParams(location.search),
      supportedFilters,
      products,
    );
    setActiveFilters(filters);
  }, [location.search]);

  return {
    activeFilters,
    filterSections,
    handleFilterChange,
  };
};

export default useFilters;
