import styled from "styled-components";

// Utils
import { rem, responsive, Opacity } from "../../utils/style";

// Components
import Module from "../Module";

const Header = styled.div`
  font-weight: 300;
  letter-spacing: 0px;
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  margin-bottom: 40px;

  ${responsive.md`
    letter-spacing: -0.3px;
    font-size: ${rem(26)};
    line-height: ${rem(36)};
    margin-bottom: 56px;
  `}

  p {
    margin: 0;
  }

  i,
  em {
    display: block;
    opacity: ${Opacity.light};
    font-size: ${rem(12)};
  }
`;

const ShopGridColumn = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const ShopGridContent = styled.div`
  display: grid;
  margin-bottom: 40px;
  align-items: stretch;

  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  grid-column-gap: 15px;
  row-gap: 40px;
  grid-row-gap: 40px;
  grid-auto-rows: minmax(100px, auto);

  ${responsive.sm`
    column-gap: 20px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  `}

  ${responsive.md`
    column-gap: 26px;
    grid-column-gap: 26px;
    row-gap: 80px;
    grid-row-gap: 80px;
    margin-bottom: 80px;
  `}

  ${responsive.lg`
    column-gap: 30px;
    grid-column-gap: 30px;
  `}
`;

const ShopGrid = (props) => {
  const { header, children } = props;

  return (
    <>
      <Module>
        <div className="col-12 col-sm-9 col-lg-8">
          {header && (
            <Header
              dangerouslySetInnerHTML={{
                __html: header.childMarkdownRemark.html,
              }}
            />
          )}
        </div>
      </Module>

      <Module>
        <ShopGridColumn className="col-12">
          <ShopGridContent>{children}</ShopGridContent>
        </ShopGridColumn>
      </Module>
    </>
  );
};

export default ShopGrid;
