import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import classNames from "classnames/bind";
import queryString from "query-string";

import FocusTrap from "focus-trap-react";
import OutsideClickHandler from "react-outside-click-handler";

// Components
import Text from "./Text";
import RitualButton from "./global/RitualButton";
import CheckboxInput from "./global/CheckboxInput";

// Utils
import { Color, media, rem, rgba, responsive, Opacity } from "../utils/style";
import { Icons } from "../utils/react-svg";
import keyCodes from "../utils/keyCode";

// Services
import intl from "../services/intl";

const AnimationTime = `0.2s`;

const SlideIn = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`;

const SlideOut = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
`;

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
`;

const FadeOut = keyframes`
  from {
    opacity: 0.5;
  }

  to {
    opacity: 0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  width: 100vw;
  height: 100vh;

  background-color: ${Color.black};

  ${media.mobile`
    animation: ${FadeOut} ${AnimationTime} ease-in-out 0s normal both;

    &.expanded {
      animation: ${FadeIn} ${AnimationTime} ease-in-out 0s normal both;
    }
  `}

  ${responsive.sm`
    background-color: transparent;
  `}
`;

const FilterButton = styled.button`
  appearance: none;
  background: none;
  border: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 22px;
  border: 1px solid ${rgba(Color.ritualBlue, 0.24)};
  padding: 10px 24px;

  color: ${Color.ritualBlue};
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  width: 100%;

  ${responsive.sm`
    width: auto;
    padding: 0;
    border: 0;
  `}

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}

  svg {
    margin-left: 16px;
    width: 16px;
  }
`;

const Selector = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;

  display: flex;
  flex-direction: column;

  width: 100vw;
  padding: 32px 24px;

  background-color: ${Color.white};

  ${media.mobile`
    animation: ${SlideOut} ${AnimationTime} ease-in-out 0s normal both;

    &.expanded {
      animation: ${SlideIn} ${AnimationTime} ease-in-out 0s normal both;
    }
  `}

  ${responsive.sm`
    position: absolute;
    left: unset;
    bottom: unset;
    top: 8px;
    right: -32px;

    width: auto;
    min-width: 370px;
    padding: 40px 32px;

    transition: none;
    box-shadow: 0px 8px 30px -10px rgba(20, 43, 111, 0.16);
  `}
`;

const SelectContainer = styled.div`
  position: relative;
  overflow: visible;
  visibility: hidden;

  ${media.mobile`
    transition: visibility 0s linear ${AnimationTime};
  `}

  &.expanded {
    visibility: visible;
    transition: none;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const Title = styled.span`
  color: ${rgba(Color.ritualBlue, Opacity.light)};
  font-weight: 500;
  letter-spacing: 0.8px;
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  text-transform: uppercase;
`;

const CloseButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: none;

  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * Renders a filtering dropdown that displays a checkbox for each of the filter
 * options. This component does not perform any filtering itself, but instead
 * returns an object containing the section filter and selected values.
 *
 * @param {Array} sections An array with containing the header, filter and
 * array of options for each section of the MagicFilter. Each element in the
 * options array has a title and a value.
 * @param {Function} onSelected A function called whenever a filter selection
 * is made. The funtion will be called with an object containing the section
 * keys and selected values.
 */
const MagicFilter = (props) => {
  const [expanded, setExpanded] = useState(false);

  let {
    className,
    active,
    sections,
    ctaText = intl.t("filter.by"),
    style,
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const params = new URLSearchParams(data);

    const activeFilters = queryString.parse(params.toString());

    // Ensure that all values in the activeFilters object are arrays.
    Object.entries(activeFilters).forEach(([filter, values]) => {
      if (!Array.isArray(values)) values = [values];
      activeFilters[filter] = values;
    });

    props.onSelected(activeFilters);
    setExpanded(false);
  };

  const handleClick = () => {
    if (props.onClick && props.onClick(expanded));
    setExpanded(!expanded);
  };

  const handleClose = () => {
    if (expanded) setExpanded(false);
  };

  const handleEscDown = (e) => {
    if (e.keyCode === keyCodes.ESC) handleClose();
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscDown);

    return () => {
      window.removeEventListener("keydown", handleEscDown);
    };
  }, [handleEscDown]);

  return (
    <FocusTrap active={expanded}>
      <div className={className} style={style}>
        <FilterButton
          onClick={handleClick}
          aria-haspopup="true"
          aria-expanded={expanded ? "true" : "false"}
          data-test-filter-button
        >
          {ctaText}
          {expanded ? <Icons.CaretUpRounded /> : <Icons.CaretDownRounded />}
        </FilterButton>

        <SelectContainer className={classNames({ expanded })}>
          <Overlay className={classNames({ expanded })} />
          <OutsideClickHandler onOutsideClick={handleClose}>
            <Selector className={classNames({ expanded })}>
              <Heading className="d-flex d-sm-none">
                <Title>
                  <Text id="filter.by" defaultMessage="Filter by" />
                </Title>
                <CloseButton
                  aria-label={intl.t("general.close", "Close")}
                  onClick={handleClose}
                >
                  <Icons.Close />
                </CloseButton>
              </Heading>

              <form onSubmit={handleSubmit}>
                {sections.map((section, i) => {
                  return (
                    <div key={i}>
                      {sections.length > 1 && section.header && (
                        <h4>{section.header}</h4>
                      )}
                      {section.options.map((option, j) => (
                        <Option
                          key={`section-${i}-option-${j}`}
                          section={section}
                          option={option}
                          active={active}
                        />
                      ))}
                    </div>
                  );
                })}

                <RitualButton
                  className="fullwidth mt-4 mt-sm-2"
                  type="submit"
                  isLink={false}
                >
                  <Text id="filter.apply" defaultMessage="Apply" />
                </RitualButton>
              </form>
            </Selector>
          </OutsideClickHandler>
        </SelectContainer>
      </div>
    </FocusTrap>
  );
};

const Option = (props) => {
  const { section, option, active } = props;
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    // If there are no active filters, the current option should not be
    // checked.
    if (!active) {
      setChecked(false);
      return;
    }

    // If there are active filters for our current section, check whether the
    // current option is active.
    const activeForSection = active[section.filter];
    setChecked(
      activeForSection ? activeForSection.includes(option.value) : false,
    );
  }, [active]);

  const name = section.filter;
  const id = `${section.filter}.${option.value}`;

  return (
    <CheckboxInput
      className="mb-4"
      key={name}
      id={id}
      name={name}
      label={option.title}
      value={option.value}
      checked={checked}
    />
  );
};

export default MagicFilter;
