import Container from "./Container";
import Row from "./Row";

export default (props) => {
  const { padding20 = true } = props;
  return (
    <Container {...props} padding20={padding20}>
      <Row>{props.children}</Row>
    </Container>
  );
};
