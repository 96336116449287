import queryString from "query-string";

export const ShopPages = {
  All: "/shop/all",
  Multivitamin: "/shop/multivitamins",
  Protein: "/shop/protein",
  Pregnany: "/shop/pregnancy",
  Synbiotic: "/shop/gut-health"
};

export const ShopFilters = {
  demographics: "d",
  ageRanges: "a",
  lifestages: "l",
};

export function getShopPage(filters) {
  let { lifestages, subCategory } = filters;

  if (subCategory === "Protein") {
    return ShopPages.Protein;
  }
  if (subCategory === "Multivitamin") {
    return ShopPages.Multivitamin;
  }
  if (lifestages && lifestages.includes("Pregnancy")) {
    return ShopPages.Pregnany;
  }
  if (subCategory === "Gut Health") {
    return ShopPages.Synbiotic;
  }

  return ShopPages.All;
}

export function getShopPath(url, filters) {
  const mappedFilters = {};
  Object.keys(filters).forEach((key) => {
    mappedFilters[`filters.${ShopFilters[key]}`] = filters[key];
  });

  const params = queryString.stringify(mappedFilters, { arrayFormat: "comma" });

  const pathWihoutParams = url.split("?")[0];

  return params ? `${pathWihoutParams}?${params}` : pathWihoutParams;
}

export function parseShopPath(search) {
  const params = new URLSearchParams(search);
  const filters = queryString.parse(params.toString(), {
    arrayFormat: "comma",
  });

  const unmappedFilters = {};
  Object.keys(filters).forEach((mappedKey) => {
    const key = Object.keys(ShopFilters).find(
      (key) => ShopFilters[key] === mappedKey.split(".")[1],
    );
    if (key) {
      unmappedFilters[key] = filters[mappedKey];
    }
  });

  return unmappedFilters;
}
