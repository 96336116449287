import { useEffect, useState } from "react";
import styled from "styled-components";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { variation } from "../../services/launchDarkly";
import metrics from "../../utils/metrics";
import { Color, Font, rem, responsive } from "../../utils/style";
import PulsePill from "../bundle/PulsePill";
import RitualButton from "../global/RitualButton";

const EditorialBlockTypes = {
  QUOTE: "Quote",
  CTA: "CTA",
};

const ColorMap = {
  Blue: Color.ritualBlue,
  White: Color.white,
};

const Wrapper = styled.div`
  position: relative;
  min-height: 298px;
  height: ${(p) => (p.type === EditorialBlockTypes.QUOTE ? "298px" : "auto")};
  over-flow: hidden;
  grid-column: span 2;
  border-radius: 8px;

  ${responsive.sm`
    grid-column: span 3;
    display: flex;
    align-items: center;
    height: auto;
  `};

  ${responsive.md`
    height: ${(p) => (p.height ? p.height : "auto")};
  `};
`;

const Content = styled.div`
  position: relative;
  z-index: 10;
  padding: 24px;

  ${responsive.sm`
    padding: 24px 40px;
  `};

  ${responsive.md`
    padding: 24px 48px;
  `};

  ${responsive.lg`
    padding: 24px 72px;
  `};

  p {
    color: ${(p) => (p.color ? ColorMap[p.color] : Color.ritualBlue)};
    font-size: ${rem(18)};
    line-height: ${rem(22)};
    font-weight: 400;
    max-width: 170px;
    margin: 0 0 24px 0;

    i,
    em {
      ${Font.dutch};
    }

    ${responsive.sm`
      font-size: ${rem(24)};
      line-height: ${rem(32)};
      max-width: 232px;
    `};

    ${responsive.md`
      font-size: ${rem(32)};
      line-height: ${rem(40)};
      margin-bottom: 56px;
      max-width: 330px;
    `};

    ${responsive.lg`
      max-width: 402px;
    `};
  }

  img {
    display: block;
    margin: 0;
    padding: 0;
    max-height: 20px;

    ${responsive.md`
      max-height: 32px;
    `};
  }
`;

const CTAContentBlock = styled.div`
  position: relative;
  z-index: 10;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${responsive.sm`
      padding: 24px;
    width: calc(50% + 48px);
  `};

  .title {
    margin-top: 200px;

    ${responsive.sm`
      margin-top: 0;
    `};

    p {
      color: ${(p) => (p.color ? ColorMap[p.color] : Color.ritualBlue)};
      font-size: ${rem(24)};
      line-height: ${rem(30)};
      letter-spacing: -1%;
      margin: 0 0 8px 0;

      ${responsive.sm`
        margin-bottom: 12px;
      `};

      ${responsive.md`
        font-size: ${rem(32)};
        line-height: ${rem(40)};
      `};
    }
  }

  .description {
    p {
      ${Font.dutch};
      color: ${(p) => (p.color ? ColorMap[p.color] : Color.ritualBlue)};
      font-size: ${rem(14)};
      line-height: ${rem(20)};
      margin: 0 0 16px 0;

      ${responsive.sm`
        margin-bottom: 24px;
      `};

      ${responsive.md`
        font-size: ${rem(16)};
        line-height: ${rem(24)};
      `};
    }
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 16px;
  overflow: hidden;

  * {
    width: 100%;
    height: 100%;
  }
`;

const QuoteContent = ({ title, logo }) => {
  return (
    <Content>
      <div
        dangerouslySetInnerHTML={{
          __html: title.childMarkdownRemark.html,
        }}
      />
      {logo && (
        <GatsbyImage
          image={getImage(logo.gatsbyImageData)}
          alt={logo.description}
          objectFit="contain"
          objectPosition="left"
        />
      )}
    </Content>
  );
};

const CTAContent = ({
  title,
  description,
  ctaText,
  ctaUrl,
  showPromoPill,
  color,
  showCtaArrow,
}) => {
  const handleClick = () => {
    metrics.track("CTA Clicked", {
      location: "Editorial Block PLP",
      title: ctaText?.ctaText,
    });
  };

  return (
    <CTAContentBlock color={color}>
      <span>{showPromoPill && <PulsePill className="no-margin" />}</span>
      <div>
        {title && (
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: title.childMarkdownRemark.html,
            }}
          />
        )}
        {description && (
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
        )}
        {ctaText && ctaUrl && (
          <RitualButton
            className={color === "White" ? "primary-cta" : ""}
            to={ctaUrl}
            arrow={showCtaArrow}
            onClick={handleClick}
          >
            {ctaText.ctaText}
          </RitualButton>
        )}
      </div>
    </CTAContentBlock>
  );
};

const EditorialBlock = (props) => {
  const {
    type,
    mobileBackground,
    desktopBackground,
    desktop,
    color = "Blue",
    pregnancyTest,
    quizTest,
    customHeight
  } = props;
  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (pregnancyTest) {
      const isSampled = variation("editorial-block-on-plp", false);
      setHide(!isSampled);
    } else if (quizTest) {
      const isSampled = variation("editorial-block-for-quiz", false);
      setHide(!isSampled);
    } else {
      setHide(false);
    }
  }, [pregnancyTest, quizTest]);

  if (hide) return null;

  const Content = type === EditorialBlockTypes.CTA ? CTAContent : QuoteContent;

  return (
    <Wrapper
      type={type}
      height={customHeight || null}
      className={desktop ? "d-none d-sm-flex" : "d-flex d-sm-none"}
      color={color}
    >
      <Content {...props} />
      <ImageWrapper>
        {mobileBackground && (
          <GatsbyImage
            image={getImage(mobileBackground.gatsbyImageData)}
            alt={mobileBackground.description}
            loading="eager"
            objectFit="cover"
            objectPosition="middle right"
            className="d-sm-none"
          />
        )}
        {desktopBackground && (
          <GatsbyImage
            image={getImage(desktopBackground.gatsbyImageData)}
            alt={desktopBackground.description}
            loading="eager"
            objectFit="cover"
            objectPosition="50% 50%"
            className="d-none d-sm-block"
          />
        )}
      </ImageWrapper>
    </Wrapper>
  );
};

export default EditorialBlock;
