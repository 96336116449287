import { forwardRef, useEffect, useRef } from "react";
import styled from "styled-components";

// Components
import MagicFilter from "../MagicFilter";
import MagicLink from "../MagicLink";
import Module from "../Module";
import ScrollableOverflow from "../ScrollableOverflow";
import PulsePill from "../bundle/PulsePill";

// Services
import intl from "../../services/intl";
import { navigate } from "../../services/navigation";

// Utils
import { ActionType } from "../../constants/product";
import useShoppingIntention from "../../hooks/useShoppingIntention";
import metrics from "../../utils/metrics";
import { orderArray } from "../../utils/order";
import { Color, Opacity, rem, responsive, rgba } from "../../utils/style";

const Title = styled.h1`
  font-weight: 500;
  letter-spacing: -1.17px;
  font-size: ${rem(40)};
  line-height: ${rem(54)};
  margin-bottom: 16px;
  ${responsive.md`
    letter-spacing: -2px;
    font-size: ${rem(66)};
    line-height: ${rem(72)};
    margin-bottom: 32px;
  `}
`;

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${rgba(Color.ritualBlue, 0.16)};
  border: 0;
  margin: 32px 0 40px;
  ${responsive.md`
    margin: 48px 0 56px;
  `}
`;

const NavContainer = styled.nav`
  position: relative;
  flex-grow: 1;

  ${responsive.sm`
    overflow: hidden;
  `};
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 30px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 10;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -10px;
    bottom: 0;
    width: 10px;
    background-color: #fff;
  }
`;

const NavigationList = styled.ul`
  display: flex;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavigationListItem = styled.li`
  font-weight: 500;
  letter-spacing: 0px;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  margin-right: 16px;

  ${responsive.md`
    letter-spacing: -0.2px;
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    margin-right: 32px;
  `};

  a > span {
    color: ${rgba(Color.ritualBlue, Opacity.light)};
    margin: 0;
  }

  &:hover,
  &.active {
    a > span {
      color: ${Color.ritualBlue};
    }
  }
`;

const NavLink = styled(MagicLink)`
  display: flex;
  span {
    margin-left: 8px;
    ${responsive.md`
      margin-left: 12px;
    `}
  }
`;

const ShopAllOrder = [
  "12KgeE7Im3nHWtLUI3f1nn", // All
  "1osPvz2gcwzknpiNRx2XKR", // Multivitamin
  "2Z0NdXrlHlCKJ6mOSTx7b", // Pregnancy
  "7ANJMYWkQ46uUIiTf9o4ko", // Gut Health
  "2YqKl3fNvFzPsdKdGkaHri", // Skin
  "5RI7kGsVApPJKBPBdbgcxf", // Sleep
  "6280d527i88UV2M36XBPJZ", // Protein
  "1udbY2A3OPsqXnmMu7FLqu", // Bundles
];

const apply = (extra) => (n) => ({ ...n, ...extra });
const excludeSleepFromUK = (locale, slug) =>
  locale !== "en-GB" || slug !== "shop/sleep";

const useNavItems = (data) => {
  const shopNodes = data.allContentfulShopLandingPage.nodes
    .filter(({ slug }) => excludeSleepFromUK(intl.locale, slug))
    .map(apply({ type: "shop" }));
  const nodes = [...shopNodes];
  const items = orderArray(nodes, ShopAllOrder, "contentful_id");
  return items;
};

const Navigation = (props) => {
  const {
    title,
    activeFilters,
    filterSections,
    handleFilterChange,
    hideNavigation,
  } = props;

  const items = useNavItems(props.data);

  const scrollableRef = useRef(null);
  const itemRefs = useRef(new Array());

  function getFilterCtaText() {
    let filterCount = 0;
    if (activeFilters) {
      Object.keys(activeFilters).forEach((key) => {
        filterCount += activeFilters[key].length;
      });
    }

    if (!filterCount) {
      // If no filters are selected, simply show the "Filter by" CTA.
      return intl.t("filter.by", "Filter by");
    } else if (filterCount === 1) {
      // If one filter is selected show the "Fitler By ${value}" CTA, where
      // value represents the label of the current filter.
      const key = Object.keys(activeFilters)[0];
      const value = activeFilters[key][0];
      const valueText = intl.t(`tags.${key}.${value}`);
      return intl.t("filter.by-value", `Filter by ${valueText}`, {
        value: valueText,
      });
    } else if (filterCount > 1) {
      // If multiple filters are selected, show the "X Filters Selected" CTA.
      return intl.t("filter.x-selected", `${filterCount} Filters Selected`, {
        amount: filterCount,
      });
    }
  }

  useEffect(() => {
    const scrollOffset = props.location.state?.scrollOffset;

    if (scrollableRef.current && itemRefs.current.length) {
      // Scroll to the previously active index without animating. This ensures
      // that the subsequent slide transition starts from the previously
      // active item.
      if (scrollOffset) {
        scrollableRef.current.scrollToPosition(scrollOffset * -1, false);
      }
      // If an item was previously active, animate a transition to the newly
      // active item.
      const activeIndex = items.findIndex((item) => item.title === title);
      scrollableRef.current.scrollToElement(
        itemRefs.current[activeIndex],
        "center",
        scrollOffset !== "undefined",
      );
    }
  }, []);

  const filterCtaText = getFilterCtaText();

  const showFilter = filterSections && !!filterSections.length;
  const handleFilterClick = () => {
    metrics.track("Secondary Nav Clicked", {
      location: "Product List Page Filter By",
      title: filterCtaText,
      nonInteraction: false,
    });
  };

  return (
    <Module allowOverflow={true}>
      <div className="col-12">
        <Title>{title}</Title>

        {!hideNavigation && (
          <div className="d-block d-sm-flex justify-content-between">
            <NavContainer>
              <div className="d-block">
                <Gradient className="d-none d-sm-block" />
                <ScrollableOverflow
                  ref={scrollableRef}
                  bleedLeft={true}
                  bleedRight={true}
                >
                  <NavigationList>
                    {items.map((item) => (
                      <NavListItem
                        key={item.slug}
                        title={title}
                        item={item}
                        ref={(e) => itemRefs.current.push(e)}
                        scrollableRef={scrollableRef}
                      >
                        {item.type === "bundle" && (
                          <>
                            <PulsePill />
                          </>
                        )}
                      </NavListItem>
                    ))}
                  </NavigationList>
                </ScrollableOverflow>
              </div>
            </NavContainer>

            {showFilter && (
              <MagicFilter
                className="mb-5 mt-4--5 mb-sm-0 mt-sm-1 mt-lg-0"
                style={{
                  minWidth: "95px",
                }}
                ctaText={filterCtaText}
                active={activeFilters}
                sections={filterSections}
                onSelected={handleFilterChange}
                onClick={handleFilterClick}
              />
            )}

            <div className="d-block d-sm-none">
              {!showFilter && <Divider />}
            </div>
          </div>
        )}

        <div className="d-none d-sm-block">
          <Divider />
        </div>
      </div>
    </Module>
  );
};

const NavListItem = forwardRef((props, ref) => {
  const { title, item, scrollableRef } = props;

  function handleClick(e) {
    e.preventDefault();

    metrics.track("Secondary Nav Clicked", {
      location: "Product List Page Category Tab",
      title: item.title,
      nonInteraction: false,
    });

    navigate(`/${item.slug}`, {
      state: {
        scrollOffset: scrollableRef?.current?.getLeftPosition(),
      },
    });
  }

  return (
    <NavigationListItem
      ref={ref}
      className={title === item.title ? "active" : ""}
    >
      <NavLink
        data-test-shop-landing-link
        to={`/${item.slug}`}
        onClick={handleClick}
        draggable={false}
      >
        <span>{item.title}</span>
        {props.children}
      </NavLink>
    </NavigationListItem>
  );
});

export default Navigation;
