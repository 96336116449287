import styled from "styled-components";
// Services
import intl from "../../services/intl";
// Utils
import { Color, Font, Opacity, rem, responsive } from "../../utils/style";
import CircleNew from "../home/Circle.New";
// Components
import Module from "../Module";
import BarrierBusters from "../product/BarrierBusters";
import ValuePropsNew from "../product/ValueProps.New";
import Text from "../Text";
import Navigation from "./Navigation";

// Styled Elements
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  margin-top: 52px;
  padding: 32px 0 40px;

  ${responsive.md`
    margin-top: 64px;
    padding: 56px 0 80px;
  `}
`;

const GraySection = styled.div.attrs({
  role: "section",
  "aria-labelledby": "barrier-buster-heading",
})`
  position: relative;
  overflow: hidden;

  background-color: rgba(242, 241, 245, ${Opacity.light});
  padding: 80px 0;

  ${responsive.md`
    padding: 120px 0;
  `}
`;

const Content = styled.div`
  z-index: 2;
  position: relative;
`;

const ValuePropsHeader = styled.p`
  position: relative;

  ${Font.circular};
  text-align: left;
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;
  font-weight: 500;

  padding-top: 28px;
  margin: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 4px;
    background-color: ${Color.ritualBlue};
  }

  ${responsive.sm`
    padding-top: 0;
    text-align: center !important;

    &::before {
      display: none;
    }
  `};

  ${responsive.md`
    max-width: 774px;
    margin: 0 auto 16px;
  `};

  ${responsive.lg`
    max-width: 970px;
  `};
`;

const BarrierBustersTitle = styled.h2`
  ${Font.circular};
  color: ${Color.ritualBlue};
  text-align: center !important;

  font-weight: 500;
  letter-spacing: -0.4px;
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  margin: 0;

  ${responsive.md`
    letter-spacing: -1.6px;
    font-size: ${rem(56)};
    line-height: ${rem(64)};
    margin-bottom: 24px;
  `};

  ${responsive.lg`
    letter-spacing: -2px;
    font-size: ${rem(66)};
    line-height: ${rem(72)};
  `};
`;

const PageTitle = styled.div`
  font-weight: 300;
  letter-spacing: 0px;
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  margin-bottom: 40px;

  ${responsive.md`
    letter-spacing: -0.3px;
    font-size: ${rem(26)};
    line-height: ${rem(36)};
    margin-bottom: 56px;
  `}

  p {
    margin: 0;
  }

  i,
  em {
    display: block;
    opacity: ${Opacity.light};
    font-size: ${rem(12)};
  }
`;

const ShopPage = (props) => {
  const { pageHeadline, body, valueProps } = props;

  return (
    <>
      <Wrapper>
        <Navigation {...props} />
        {pageHeadline && (
          <Module>
            <div className="col-12">
              <PageTitle
                dangerouslySetInnerHTML={{
                  __html: pageHeadline.childMarkdownRemark.html
                    .replace("<p>", "")
                    .replace("</p>", ""),
                }}
              ></PageTitle>
            </div>
          </Module>
        )}
        {props.children}
        <Content>
          <Module>
            <div className="col-12">
              <ValuePropsHeader
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html
                    .replace("<p>", "")
                    .replace("</p>", ""),
                }}
              />
            </div>
          </Module>
          <ValuePropsNew valueProps={valueProps} />
        </Content>
        <CircleNew className="right bottom obscured" />
      </Wrapper>
      <BarrierBustersSection />
    </>
  );
};

const BarrierBustersSection = () => {
  return (
    <GraySection>
      <Content>
        <Module>
          <div className="col-12 col-sm-10 offset-sm-1">
            <BarrierBustersTitle id="barrier-buster-heading">
              <Text
                id="shop.barrier-buster.title"
                defaultMessage="Making healthy habits easy"
              />
            </BarrierBustersTitle>
            <BarrierBusters
              items={intl.unformattedTranslation("shop.barrier-busters")}
            />
          </div>
        </Module>
      </Content>
      <CircleNew className="right top lighter" />
    </GraySection>
  );
};

export default ShopPage;
